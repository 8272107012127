<template>
    <div v-loading="isLoadingChart">
        <div>
            <div class="d-flex align-items-center align-content-center">
                <div>
                    
                </div>
                <div class="d-flex align-items-center">
                    <select class="form-control form-control-sm mr-2"
                            style="width: 12rem;" v-model="numberOfCycle"
                            @change="getStatisticsRegisteredRechargeSameDate">
                        <option value="6">Last 6 cycles of 7 days</option>
                        <option value="8">Last 8 cycles of 7 days</option>
                        <option value="10">Last 10 cycles of 7 days</option>
                        <option value="12">Last 12 cycles of 7 days</option>
                    </select>
                    <button class="btn btn-sm btn-primary" @click="getStatisticsRegisteredRechargeSameDate">
                        <i class="fas fa-sync-alt mr-2"></i> Refresh
                    </button>
                </div>
            </div>
        </div>
        <highcharts class="mt-4 mr-1" :options="statisticsRegisteredRecharge.options"></highcharts>
        <hr/>
    </div>
</template>
<script>
    import baseComponent from "@/scripts/baseComponent"; 
    import chartDataApi from '@/api/common/chartData';
    import Highcharts from 'highcharts';
    import { 
        areaSplineChart,
        colorSun, colorSat, colorNormal
    } from "@/constant/dashboardChartOptions";
    import { Chart } from 'highcharts-vue';
    var jsonToPivotjson = require("json-to-pivot-json");
    import moment from "moment";
    var numeral = require('numeral');
    export default {
        components: {
            highcharts: Chart
        },
        data() {
            return {
                isLoadingChart: false,
                radioTargetView: 'all',
                numberOfCycle : 10,
                statisticsRegisteredRecharge: {
                    options: Object.assign(JSON.parse(JSON.stringify(areaSplineChart)), {
                        chart: {
                            height: 550,
                            type: 'areaspline'
                        },
                        yAxis: [{ // Primary yAxis
                            labels: {
                                format: '{value} user(s)',
                                style: {
                                    color: Highcharts.getOptions().colors[1]
                                }
                            },
                            title: {
                                text: 'Number of users',
                                style: {
                                    color: Highcharts.getOptions().colors[1],
                                    "font-size": 15,
                                }
                            }
                        },{ // Secondary yAxis
                            title: {
                                text: 'Amount',
                                style: {
                                    "font-size": 15,
                                }
                            },
                            labels: {
                                format: '${value}',
                            },
                            opposite: true
                        }],
                        tooltip: {
                            shared: true
                        },
                        plotOptions: {
                            areaspline: {
                                dataLabels: {
                                    enabled: true
                                },
                                fillOpacity: 0.5
                            }
                        },
                    }),
                    tableData: []
                }
            };
        },
        mounted() {
            this.getStatisticsRegisteredRechargeSameDate();
        },
        methods: {
            getStatisticsRegisteredRechargeSameDate() {
                this.isLoadingChart = true;
                chartDataApi.getStatisticsRegisteredRechargeSameDate(this.numberOfCycle).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.statisticsRegisteredRecharge.tableData = response.data.data;
                        this.statisticsRegisteredRecharge.options.xAxis.categories = [];
                        this.statisticsRegisteredRecharge.options.xAxis.categories = response.data.data.map(x => {
                            return `${moment.utc(x.DateStart).format('DD/MM/YYYY')} to ${moment.utc(x.DateEnd).format('DD/MM/YYYY')}`;
                        });

                        this.statisticsRegisteredRecharge.options.series = [{
                            name: "User recharged same date registered",
                            yAxis: 0,
                            type: 'column',
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valueSuffix: ' users'
                            },
                            data: response.data.data.map(x => {
                                return { y: x.CountUserRechargeSameDate };
                            })
                        },{
                            name: "User recharged after 1 day registered",
                            yAxis: 0,
                            type: 'column',
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valueSuffix: ' users'
                            },
                            data: response.data.data.map(x => {
                                return { y: x.CountUserRechargeAfter1Date };
                            })
                        },{
                            name: "Total amount recharged same date registered",
                            yAxis: 1,
                            type: 'spline',
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valuePrefix: '$'
                            },
                            data: response.data.data.map(x => {
                                return { y: x.TotalRechargeSameDate };
                            })
                        },{
                            name: "Total amount recharged after 1 day registered",
                            yAxis: 1,
                            type: 'spline',
                            dataLabels: {
                                enabled: true
                            },
                            tooltip: {
                                valuePrefix: '$'
                            },
                            data: response.data.data.map(x => {
                                return { y: x.TotalRechargeAfter1Date };
                            })
                        }];

                        this.isLoadingChart = false;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                        this.isLoadingChart = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.isLoadingChart = false;
                });
            },
        }
    }
</script>